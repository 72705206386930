<template>
  <div class="own-container">
    <div class="bread">个人中心 / <span>修改手机号</span></div>
    <el-row class="form-body">
      <h5>修改手机号</h5>
      <el-form ref="userForm" :model="formData" label-width="100px" :label-position="labelPosition" :rules="ruleUser" >
        <el-form-item prop="oldPhone" label="当前手机号" >
          <el-input v-model="formData.oldPhone" type="text" placeholder="请输入当前手机号" disabled></el-input>
        </el-form-item>
        <el-form-item prop="newPhone" label="新的手机号" >
          <el-input v-model="formData.newPhone" type="text" placeholder="新的手机号" maxlength="11">
            <el-button slot="append"
                       v-if="isSend==0" @click="getCode()">获取验证码</el-button>
            <el-button slot="append" v-if="isSend==1">{{ count }}s</el-button>
            <el-button slot="append" v-if="isSend==2" @click="getCode">重新获取</el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="code" label="验证码" >
          <el-input v-model="formData.code" type="text" placeholder="请输入验证码" maxlength="6"></el-input>
        </el-form-item>

      </el-form>
      <div class="line">
        <el-button type="default" size="small">取消</el-button>
        <el-button type="primary" size="small" @click="handleChangePhone()">保存</el-button>
      </div>
    </el-row>
  </div>
</template>

<script>
import {Sms, updateDetail} from '@/utils/api/own'


export default {
  name: "phone",
  data(){
    return{
      labelPosition:"right",
      isSend:0, // 0验证码未发送，1发送倒计时，3再次发送
      count:60, //60秒倒计时
      formData:{
        oldPhone:"",
        phone:"",
        code:""
      },
      ruleUser:{
        oldPhone:[
          { required: true, message: '请输入旧手机号', trigger: 'blur' },
        ],
        newPhone:[
          { required: true, message: '请输入新手机号', trigger: 'blur' },
        ],
        code:[
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      }
    }
  },
  created() {
    this.formData.oldPhone=this.$store.state.userInfo.phone
  },
  methods: {
    getCode() {
      if(!this.formData.newPhone){
        this.$message.error("新手机号不能为空")
        return false
      }
      if(this.formData.newPhone.length !== 11){
        this.$message.error("请输入正确手机号")
        return false
      }
      let data = {
        phone: this.formData.newPhone
      }
      //此处获取验证码应该是登陆后获取验证码，此处是个bug
      Sms(data).then(res => {
        if(res.code === 0 ){
          this.isSend = 1
          this.$message.success("验证码发送成功")
          let timeId = setInterval(()=>{
            this.count --
            if(this.count === 0){
              this.isSend = 0
              window.clearInterval( )
            }
          },1000)
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    // 修改手机号
    handleChangePhone(){
      if(!this.formData.code){
        this.$message.success("验证码有误")
      }
      let data = {
        phone: this.formData.newPhone
      }
      data.cmd = 2
      updateDetail(data).then(res=>{
        if(res.code === 0){
          this.$message.success("修改成功")
          this.$refs['userForm'].resetFields()
        }else{
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style  lang="less">
.form-body{
  background-color: #FFFFFF;
  padding: 30px 30px 60px 30px;
  h5{
    height: 22px;
    font-size: 16px;
    font-weight: 800;
    color: #333333;
    line-height: 22px;
    margin-bottom: 30px;
  }
  .el-form{
    width: 80%;
  }
  .el-form-item__label{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  .el-form-item__content {
    line-height: 42px;
    .el-input{
      width: 360px;
      .el-input__inner {

        height: 42px;
        line-height: 42px;
        background: #FAFAFA;
        border-radius: 2px;
        border: none;
      }
    }
    .el-input-group__append{
      background-color: #FAFAFA;
      border: none;
      position: relative;
      height: 42px;
      line-height: 42px;
      color: #3C6CFC;
      font-size: 14px;
      &:before{
        content: '';
        width: 1px;
        height: 60%;
        background-color: #AAAAAA;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .line{
    text-align: right;
    border-top: 1px solid #F1F1F1;
    padding-top: 30px;
  }
}

</style>

